<template>
  <section>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <div class="text-left">
        <template v-if="!isCompany">
          <div
            v-for="(item,index) in policyholderFormList"
            :key="'person'+index"
            class="form-item form-half">
            <template v-if="!item.noshow">
              <div
                v-if="item.type!=5"
                class="flex-col text-left">
                <span :content="item.en_content">{{item.en}}</span>
                <span :content="item.zh_content">{{item.zh}}</span>
              </div>
              <template v-else>
                <el-form-item
                  ref="policy_holder_id_type"
                  prop="policy_holder_id_type"
                  :show-message="false"
                  :rules="rules.policy_holder_id_type"
                >
                  <v-radio-group
                    v-model="formData.policy_holder_id_type"
                    flex
                    :list="personalIdTypOptions">

                  </v-radio-group>
                </el-form-item>
              </template>
              <el-form-item
                :ref="item.key"
                :prop="item.key"
                :show-message="item.key=='policy_holder_last_name'?false:true"
                :rules="rules[item.key]"
              >
                <template v-if="item.type==1">
                  <v-input
                    v-model="formData[item.key]"
                    :preappend="item.preappend"
                    :type="item.keyType"
                    :is-upper="item.isUpper"
                    :is-low="item.isLow"
                    :key-number="item.keyNumber"
                    :maxlength="item.maxlength"
                    :suffixText="item.append">
                  </v-input>
                </template>
                <template v-if="item.type==2">
                  <v-select
                    v-model="formData[item.key]"
                    :labelKey="item.labelKey||'name'"
                    :list="item.list"></v-select>
                </template>
                <template v-if="item.type==3">
                  <v-radio-group
                    v-model="formData[item.key]"
                    block
                    :labelKey="item.labelKey||'name'"
                    border
                    :list="item.list"></v-radio-group>
                </template>
                <template v-if="item.type==4">
                  <v-birth-day
                    v-model="formData[item.key]"
                  ></v-birth-day>
                </template>
                <template v-if="item.type==5">
                  <v-hkid-input
                    v-if="formData.policy_holder_id_type!=2"
                    v-model="formData[item.key]"
                  ></v-hkid-input>
                  <v-input
                    v-else
                    v-model="formData[item.key]"
                    :preappend="item.preappend"
                    :type="item.keyType"
                    :suffixText="item.append">
                  </v-input>
                </template>
              </el-form-item>
              <el-form-item
                v-if="item.key=='policy_holder_occ_id'&&formData.policy_holder_occ_id==70"
                ref="policy_holder_occ"
                prop="policy_holder_occ"
                :rules="rules.input">
                <v-input
                  v-model="formData.policy_holder_occ"
                  :placeholder="'Please enter'"
                >
                </v-input>
              </el-form-item>
            </template>
          </div>
        </template>
        <template v-else>
          <div
            v-for="(item,index) in policyholderFormCompanyList"
            :key="'commpany'+index"
            class="form-item form-half">
            <template v-if="!item.noshow">
              <div class="flex-col text-left">
                <span :content="item.en_content">{{item.en}}</span>
                <span :content="item.zh_content">{{item.zh}}</span>
              </div>
              <div class="flex-col text-left color-gray">
                <span class="fs-12 lh-150">{{item.en_tip}}</span>
                <span class="fs-12 lh-150">{{item.zh_tip}}</span>
              </div>
              <el-form-item
                :ref="item.key"
                :prop="item.key"
                :rules="rules[item.key]"
              >
                <template v-if="item.type==1">
                  <v-input
                    v-model="formData[item.key]"
                    :preappend="item.preappend"
                    :type="item.keyType"
                    :key-number="item.keyNumber"
                    :maxlength="item.maxlength"
                    :is-upper="item.isUpper"
                    :is-low="item.isLow"
                    :suffixText="item.append">
                  </v-input>
                </template>
                <template v-if="item.type==2">
                  <v-select
                    v-model="formData[item.key]"
                    :labelKey="item.labelKey||'name'"
                    :list="item.list"></v-select>
                </template>
                <template v-if="item.type==3">
                  <v-radio-group
                    v-model="formData[item.key]"
                    block
                    :labelKey="item.labelKey||'name'"
                    border
                    :list="item.list"></v-radio-group>
                </template>
                <template v-if="item.type==4">
                  <v-birth-day
                    v-model="formData[item.key]"
                  ></v-birth-day>
                </template>
                <template v-if="item.type==5&&formData.policy_holder_id_type!=2">
                  <v-hkid-input
                    v-model="formData[item.key]"
                  ></v-hkid-input>
                </template>
              </el-form-item>
              <el-form-item
                v-if="item.key=='holder_company_nature_id'&&formData.holder_company_nature_id==48"
                ref="holder_company_nature"
                prop="holder_company_nature"
                :rules="rules.input">
                <v-input
                  v-model="formData.holder_company_nature"
                  :placeholder="'Please enter'"
                >
                </v-input>
              </el-form-item>
            </template>
          </div>
        </template>
        <div class="form-item form-half form-item__line">
          <p  class="lh-150 fs-20">Correspondence Address</p>
          <p  class="mg-t-4 lh-150 fs-26">通訊地址</p>
        </div>
        <div class="flex form-half address">
          <div
            v-for="(item,index) in policyholderAddressFormList"
            :key="'address_'+index"
            class="form-item form-half"
          >
            <div class="flex-col text-left">
              <span :content="item.en_content">{{item.en}}</span>
              <span :content="item.zh_content">{{item.zh}}</span>
            </div>
            <el-form-item
              :ref="item.key"
              :show-message="false"
              :prop="item.key"
              :rules="rules[item.key]"
            >
              <v-input
                v-model="formData.correspondence_address[item.key]"
                :suffixText="item.append">
              </v-input>
            </el-form-item>
          </div>
        </div>
        <div
          v-for="(item,index) in policyholderAddressFormList1"
          :key="'address_form'+index"
          class="form-item form-half"
        >
          <div class="flex-col text-left">
            <span :content="item.en_content">{{item.en}}</span>
            <span :content="item.zh_content">{{item.zh}}</span>
          </div>
          <el-form-item
            :ref="item.key"
            :prop="item.key"
            :show-message="item.key=='building'?false:true"
            :rules="rules[item.key]"
          >
            <template v-if="item.type==1">
              <v-input
                v-model="formData.correspondence_address[item.key]"
                :suffixText="item.append">
              </v-input>
            </template>
            <template v-if="item.type==2">
              <v-select
                v-if="item.key!=='area'"
                v-model="formData.correspondence_address[item.key]"
                :list="item.list"
                :labelKey="item.labelKey||'name'"
                @change="changeDistrict"
              ></v-select>
              <v-select
                v-else
                v-model="formData.correspondence_address[item.key]"
                :list="item.list"
                :labelKey="item.labelKey||'name'"
                @change="changeArea"
              ></v-select>
            </template>
          </el-form-item>
        </div>
        <template v-if="isCompany">
          <div class="form-item form-half form-item__line">
            <p  class="lh-150 fs-20">Contact Person</p>
            <p  class="mg-t-4 lh-150 fs-26">聯絡人</p>
          </div>
          <div
            v-for="(item,index) in policyholderCompanyAccountFormList"
            :key="'company_account_form'+index"
            class="form-item form-half"
          >
            <div
              class="flex-col text-left">
              <span :content="item.en_content">{{item.en}}</span>
              <span :content="item.zh_content">{{item.zh}}</span>
            </div>
            <el-form-item
              :ref="item.key"
              :prop="item.key"
              :rules="rules[item.key]"
            >
              <v-input
                v-model="formData[item.key]"
                :preappend="item.preappend"
                :type="item.keyType"
                :maxlength="item.maxlength"
                :key-number="item.keyNumber"
                :suffixText="item.append">
              </v-input>
            </el-form-item>
          </div>

          <!--          <div class="form-item form-half form-item__line">-->
          <!--            <p  class="lh-150 fs-20">Named Driver Information</p>-->
          <!--            <p  class="mg-t-4 lh-150 fs-26">記名司機資料</p>-->
          <!--          </div>-->
          <!--          <div-->
          <!--            v-for="(item,index) in driverFormList"-->
          <!--            :key="'driver'+index"-->
          <!--            class="form-item form-half"-->
          <!--          >-->
          <!--            <template  v-if="!item.noshow">-->
          <!--              <div-->
          <!--                v-if="item.type!=5"-->
          <!--                class="flex-col text-left">-->
          <!--                <span :content="item.en_content">{{item.en}}</span>-->
          <!--                <span :content="item.zh_content">{{item.zh}}</span>-->
          <!--              </div>-->
          <!--              <template v-else>-->
          <!--                <el-form-item-->
          <!--                  ref="driver.0.id_type"-->
          <!--                  prop="driver.0.id_type"-->
          <!--                  :show-message="false"-->
          <!--                  :rules="rules.select"-->
          <!--                >-->
          <!--                  <v-radio-group-->
          <!--                    v-model="formData.driver[0].id_type"-->
          <!--                    flex-->
          <!--                    :list="personalIdTypOptions">-->

          <!--                  </v-radio-group>-->
          <!--                </el-form-item>-->
          <!--              </template>-->
          <!--              <div class="flex-col text-left color-gray">-->
          <!--                <span class="fs-12 lh-150">{{item.en_tip}}</span>-->
          <!--                <span class="fs-12 lh-150">{{item.zh_tip}}</span>-->
          <!--              </div>-->
          <!--              <el-form-item-->
          <!--                :ref="item.key=='policy_holder_is_driver'?item.key:`driver.0.${item.key}`"-->
          <!--                :show-message="showRuleMessage"-->
          <!--                :prop="item.key=='policy_holder_is_driver'?item.key:`driver.0.${item.key}`"-->
          <!--                :rules="item.key=='id_no'||item.key=='birth'?rules[item.key]:ruleType(item.type)"-->
          <!--              >-->
          <!--                <template v-if="item.type==1">-->
          <!--                  <v-input-->
          <!--                    v-model="formData.driver[0][item.key]"-->
          <!--                    :disabled="item.disabled"-->
          <!--                    :suffixText="item.append">-->
          <!--                  </v-input>-->
          <!--                </template>-->
          <!--                <template v-if="item.type==2">-->
          <!--                  <v-select-->
          <!--                    v-model="formData.driver[0][item.key]"-->
          <!--                    :disabled="item.disabled"-->
          <!--                    :label-key="'name1'"-->
          <!--                    :list="item.list"></v-select>-->
          <!--                </template>-->
          <!--                <template v-if="item.type==3">-->
          <!--                  <v-radio-group-->
          <!--                    v-if="item.key=='policy_holder_is_driver'"-->
          <!--                    v-model="formData.policy_holder_is_driver"-->
          <!--                    block-->
          <!--                    border-->
          <!--                    :list="item.list"-->
          <!--                    @change="handleSameDriver"></v-radio-group>-->
          <!--                  <v-radio-group-->
          <!--                    v-else-->
          <!--                    v-model="formData.driver[0][item.key]"-->
          <!--                    :disabled="item.disabled"-->
          <!--                    block-->
          <!--                    border-->
          <!--                    :list="item.list"></v-radio-group>-->
          <!--                </template>-->
          <!--                <template v-if="item.type==4">-->
          <!--                  <v-birth-day-->
          <!--                    v-model="formData.driver[0][item.key]"-->
          <!--                    :disabled="item.disabled"-->
          <!--                  ></v-birth-day>-->
          <!--                </template>-->
          <!--                <template v-if="item.type==5">-->
          <!--                  <v-hkid-input-->
          <!--                    v-if="formData.driver[0].id_type!=2"-->
          <!--                    v-model="formData.driver[0][item.key]"-->
          <!--                  ></v-hkid-input>-->
          <!--                  <v-input-->
          <!--                    v-else-->
          <!--                    v-model="formData.driver[0][item.key]"-->
          <!--                    :preappend="item.preappend"-->
          <!--                    :type="item.keyType"-->
          <!--                    :suffixText="item.append">-->
          <!--                  </v-input>-->
          <!--                </template>-->
          <!--              </el-form-item>-->
          <!--            </template>-->
          <!--          </div>-->
        </template>
        <v-button @click="handleNext(true)">下一步 Next</v-button>
      </div>
    </el-form>
  </section>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import MixinsPolicyForm from '@/mixins/policyForm'
import MixinsOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import IDCheck from '@/utils/idcheck'
import dayjs from 'dayjs'
export default {
  name: 'policyholderForm',
  mixins:[MixinsOptions,MixinRules,MixinsPolicyForm],
  data() {
    const checkAddress = (rule, value, callback) => {
      let {correspondence_address} = this.formData||{}
      let {room,floor,block,building,street} = correspondence_address||{}
      let arr =[
        room,
        floor,
        block,
        building,
        street,
      ]
      let isNullNum = 0
      arr.filter(item=>{
        if(!!item){
          isNullNum = isNullNum+1
        }
      })
      if(isNullNum==0) {
        callback(new Error('Fill in at least one of the address fields'))
      }
      callback()
    }
    const checkName = (rule, value, callback) => {
      let {policy_holder_first_name,policy_holder_last_name} = this.formData||{}
      if(!policy_holder_first_name&&!policy_holder_last_name) {
        callback(new Error('Fill in at least one of the content 最少填寫一項內容'))
      }
      callback()
    }
    const checkDistrictAddress = (rule, value, callback) => {
      let {correspondence_address} = this.formData||{}
      let {district} = correspondence_address||{}
      if(!district) {
        callback(new Error('Please select 請選擇'))
      }
      callback()
    }
    const checkAreaAddress = (rule, value, callback) => {
      let {correspondence_address} = this.formData||{}
      let {area} = correspondence_address||{}
      if(!area) {
        callback(new Error('Please select 請選擇'))
      }
      callback()
    }
    const checkIdNo = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      let {client_type} = this.quoteInformationForm||{}
      const { id_no,id_type } = this.formData.driver[0]
      if( value&&!IDCheck(value)&&id_type==1) {
        callback(new Error('請輸入正確的身份證號碼'))
      }
      if(!id_no&&id_type==2)
      {
        callback(new Error('Please enter 請輸入'))
      }
      // else {
      //   if(!id_no && !value) {
      //     callback(new Error('請輸入正確的身份证号码'))
      //   }
      // }
      callback()
    }
    const checkBirth = (rule, value, callback) => {
      const { birth } = this.formData.driver[0]
      if(!birth)
      {
        callback(new Error('Please enter 請輸入'))
      }
      let isAfter = this.check18Years(birth,new Date())
      if(!isAfter){
        callback(new Error('Must be 18 years of age or above\n' +
          '必須年滿18歲'))
      }
      callback()
    }
    const checkPolicyHolderId = (rule, value, callback) => {
      const { policy_holder_id_no,policy_holder_id_type } = this.formData
      console.log('policy_holder_id_no---',policy_holder_id_no)
      if( policy_holder_id_no&&!IDCheck(policy_holder_id_no)) {
        callback(new Error('請輸入正確的身份證號碼'))
      }
      if(!policy_holder_id_no)
      {
        callback(new Error('Please enter 請輸入'))
      }
      // else {
      //   if(!id_no && !value) {
      //     callback(new Error('請輸入正確的身份证号码'))
      //   }
      // }
      callback()
    }
    const checkPolicyBirth = (rule, value, callback) => {
      const { policy_holder_birth,policy_holder_id_type } = this.formData
      if(!policy_holder_birth)
      {
        callback(new Error('Please enter 請輸入'))
      }
      let isAfter = this.check18Years(policy_holder_birth,new Date())
      if(!isAfter){
        callback(new Error('Must be 18 years of age or above\n' +
          '必須年滿18歲'))
      }
      callback()
    }
    return {
      nextPath:'/quoting/insure/driver',
      isFirst:true,
      isFirstName:true,
      formData: {
        /////policyholder
        policy_holder_first_name: '',//r
        policy_holder_last_name: '',//r
        policy_holder_title: '',//r
        policy_holder_mobile: '',//r
        policy_holder_email: '',
        policy_holder_id_no: '',
        policy_holder_pp_no: '',
        policy_holder_id_type: 1,//1-hkid 2-passport 3-br
        policy_holder_occ_id: '',
        policy_holder_occ: '',
        policy_holder_birth: '',
        policy_holder_is_driver:'',
        holder_company_name:'',
        holder_company_id_no:'',
        holder_company_email:'',
        holder_company_nature_id:'',
        holder_company_nature:'',
        holder_contact_name:'',
        holder_contact_email:'',
        holder_contact_phone:'',
        holder_contact_position:'',
        holder_company_phone:'',
        correspondence_address: {
          room: '',
          floor: '',
          block: '',
          building: '',
          street: '',
          district: '',
          area: '',
          region: ''
        },
        is_delivery_purpose:''
        // driver:[
        //   {
        //     first_name:'',
        //     last_name:'',
        //     title:'',
        //     occ_id:'',
        //     birth:'',
        //     id_no:'',
        //     pp_no:'',
        //     id_type:1,
        //     relationship_id:'',
        //     driving_licence_id:'',
        //     has_accident:1,
        //     accidents:'',
        //     is_deducted_point:'',
        //     deducted_points:'',
        //   }
        // ]
      },
      rules: {
        room: [
          { validator: checkAddress, trigger: ['change', 'blur'] }
        ],
        floor: [
          { validator: checkAddress, trigger: ['change', 'blur'] }
        ],
        block: [
          { validator: checkAddress, trigger: ['change', 'blur'] }
        ],
        building: [
          { validator: checkAddress, trigger: ['change', 'blur'] }
        ],
        street: [
          { validator: checkAddress, trigger: ['change', 'blur'] }
        ],
        district: [
          { validator: checkDistrictAddress, trigger: ['change', 'blur'] }
        ],
        area: [
          { validator: checkAreaAddress, trigger: ['change', 'blur'] }
        ],
        id_no: [
          { validator: checkIdNo }
        ],
        birth: [
          { validator: checkBirth, trigger: ['change', 'blur'] }
        ],
        policy_holder_id_no: [
          { validator: checkPolicyHolderId, trigger: ['blur'] }
        ],
        policy_holder_birth: [
          {  validator: checkPolicyBirth, trigger: ['change', 'blur']}
        ],
        policy_holder_last_name: [
          {  validator: checkName, trigger: ['change', 'blur']}
        ],
        policy_holder_first_name: [
          {  validator: checkName, trigger: ['change', 'blur']}
        ],
      }
    }
  },
  computed:{
    policy_holder_name(){
      let {policy_holder_first_name,policy_holder_last_name} = this.formData||{}
      return !policy_holder_first_name&&!policy_holder_last_name
    },
    isCompany(){
      let {client_type} = this.quoteInformationForm||{}
      return client_type==2
    },
    addressValid(){
      let {correspondence_address} = this.formData||{}
      let {room,floor,block,building,street} = correspondence_address||{}
      let arr =[
        room,
        floor,
        block,
        building,
        street,
      ]
      let isNullNum = 0
      arr.filter(item=>{
        if(!!item){
          isNullNum = isNullNum+1
        }
      })
      return isNullNum>0?true:false
    }
  },
  watch:{
    quoteInformationForm:{
      immediate:true,
      handler(val){
        Object.keys(this.formData).some(key=>{
          if(val&&val[key]){
            this.formData[key] = val[key]
          }
        })
        let {vehicle_detail} = val||{}
        let {is_delivery_purpose} = vehicle_detail||{}
        this.is_delivery_purpose = is_delivery_purpose
      }
    },
    addressValid:{
      immediate:true,
      handler(val){
        this.$nextTick(()=>{
          if(this.$refs.form){
            if(!val&&!this.isFirst){
              this.$refs.form.validateField(['room','floor','block','building','street'])
            }
            else  this.$refs.form.clearValidate(['room','floor','block','building','street'])
            this.isFirst = false
          }
        })
      }
    },
    policy_holder_name:{
      immediate:true,
      handler(val){
        this.$nextTick(()=>{
          if(this.$refs.form){
            if(val&&!this.isFirstName){
              this.$refs.form.validateField(['policy_holder_first_name','policy_holder_last_name'])

            }
            else  this.$refs.form.clearValidate(['policy_holder_first_name','policy_holder_last_name'])
            this.isFirstName = false
          }
        })
      }
    }
  },
  mounted() {

  },
  destroyed() {
    // this.handleSameDriver()
  },
  methods:{
    changeDistrict(id) {
      let { area, region } = this.formData.correspondence_address
      const pid = this.getAreaData('district', id).pid
      // 檢查 area
      if(area !== pid) {
        this.formData.correspondence_address.area = pid
      }
      // 檢查 region
      const areaPid = this.getAreaData('area', pid).pid
      if(region !== areaPid) {
        this.formData.correspondence_address.region = areaPid
      }
    },
    changeArea(id) {
      let { region, district } = this.formData.correspondence_address
      const pid = this.getAreaData('area', id).pid
      if(region !== pid) {
        this.formData.correspondence_address.region = pid
      }
      const districtPid = this.getAreaData('district', district).pid
      if(districtPid !== id) {
        this.formData.correspondence_address.district = ''
      }
    },
    changeRegion(id) {
      const areaPid = this.getAreaData('area', this.formData.correspondence_address.area).pid
      if(areaPid !== id) {
        this.formData.area = ''
        this.formData.district = ''
      }
    },
    handleSameDriver(){
      let {policy_holder_first_name,policy_holder_last_name,policy_holder_title,policy_holder_id_no,policy_holder_pp_no,policy_holder_id_type,policy_holder_occ_id,policy_holder_birth} = this.formData||{}
      let {driver,policy_holder_is_driver} = this.quoteInformationForm||{}
      if(policy_holder_is_driver==1){
        let params ={
          first_name:policy_holder_first_name,
          last_name:policy_holder_last_name,
          title:policy_holder_title,
          occ_id:policy_holder_occ_id,
          birth:policy_holder_birth,
          id_no:policy_holder_id_no,
          pp_no:policy_holder_pp_no,
          id_type:policy_holder_id_type,
          relationship_id:8
        }
        let first_driver = driver[0]
        first_driver = {
          ...driver[0],
          ...params
        }
        this.setQuoteInformationForm({
          ...this.quoteInformationForm,
          driver:first_driver
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.address{
  .form-half{
    width: calc(50% - 16px);
    margin-right: 16px;
    &:last-child{
      margin-right: 0;
    }
  }
}
</style>
